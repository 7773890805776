import { useState, useEffect } from 'react';

import usePrevious from 'hooks/usePrevious';

export default function DateSelect(props) {
    const monthNameList = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];

    const [year, setYear] = useState('');
    const [yearList, setYearList] = useState([]);
    const prevYear = usePrevious(year);

    const [month, setMonth] = useState('');
    const [monthList, setMonthList] = useState([]);
    const prevMonth = usePrevious(month);

    const [day, setDay] = useState('');
    const [dayList, setDayList] = useState([]);

    useEffect(() => {
        if (props.startDate) {
            const currentYear = new Date().getFullYear();
            let _yearOptionList = [];
            for (let i = props.startDate.getFullYear(); i <= currentYear; i++) _yearOptionList.push({ 'label': i, 'value': i });
            setYearList(_yearOptionList);
        }
    }, [props.startDate]);

    useEffect(() => {
        if (year !== prevYear) {
            const currentYear = new Date().getFullYear();
            const selectedYear = new Date(year).getFullYear();

            // const monthFrom = 0;
            let monthTo = 11;

            if (selectedYear === currentYear) monthTo = new Date().getMonth();

            let _monthList = [];
            for (let i = 0; i <= monthTo; i++) _monthList.push({ 'label': monthNameList[i], 'value': i });
            if (monthTo > parseInt(month)) setMonth(monthTo);
            setMonthList(_monthList);
        }

        if (year !== prevYear || month !== prevMonth) {
            let dayCount = 31;
            if (year !== '' && month !== '') {
                let lastDayOfMonth = new Date(parseInt(year), parseInt(month) + 1, 0);
                dayCount = lastDayOfMonth.getDate();
            }
            let _dayList = [];
            for (let i = 1; i <= dayCount; i++) _dayList.push({ 'label': i, 'value': i });
            if (parseInt(day) > dayCount) setDay(dayCount);
            setDayList(_dayList);
        }

        if (year !== '' && month !== '' && day !== '') {
            let result = new Date(parseInt(year), parseInt(month), parseInt(day));
            if (props.onValueChange) props.onValueChange(result);
        } else {
            if (props.onValueChange) props.onValueChange(null);
        }
    }, [year, month, day]);

    // useEffect(() => {
    //     if (props.value) {
    //         console.log(props.value);

    //         // console.log(props.value.getFullYear());
    //         console.log(props.value.getMonth());
    //         // console.log(props.value.getDate());
    //         // const date = new Date(props.value);
    //         // console.log(date);
    //         setYear(props.value.getFullYear());
    //         // setMonth(props.value.getMonth());
    //         // setDay(props.value.getDate());
    //     }
    // }, [props.value]);

    return (
        <div className='flex-1 flex flex-row gap-4'>

            <select className='flex-1 generic-select' value={year} onChange={(e) => setYear(e.target.value)}>
                <option value='' disabled selected hidden>Év</option>
                {yearList && yearList.map((item, index) => <option key={item.value} value={item.value} className='generic-select-option'>{item.label}</option>)}
            </select>

            <select className='w-[50%] generic-select' value={month} onChange={(e) => setMonth(e.target.value)}>
                <option value='' disabled selected hidden>Hónap</option>
                {monthList && monthList.map((item, index) => <option key={item.value} value={item.value} className='generic-select-option'>{item.label}</option>)}
            </select>

            <select className='flex-1 generic-select' value={day} onChange={(e) => setDay(e.target.value)}>
                <option value='' disabled selected hidden>Nap</option>
                {dayList && dayList.map((item, index) => <option key={item.value} value={item.value} className='generic-select-option'>{item.label}</option>)}
            </select>

        </div>
    );
}