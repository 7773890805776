import axios from 'axios';
import { useState, useEffect } from 'react';
import Moment from 'react-moment';
import 'moment/locale/hu';

import PageTitle from 'elements/PageTitle';
import Graph from 'elements/Graph';
import Table from 'elements/Table';
import DateSelect from 'elements/DateSelect';
import DataLister from 'elements/DataLister';
import { SubFooter, SubFooterButtonItem } from 'components/Footer';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { getByParam, convertToLegacyDate } from 'utils/GenericUtils';
import { useWebsite } from 'providers/WebsiteContextProvider';

export default function CalculatorPage(props) {
    const [page, setPage] = useState(null);

    const { setHeaderDark } = useWebsite();

    const [fundList, setFundList] = useState([]);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => { setHeaderDark(); }, []);

    useEffect(() => {
        const url = 'https://www.diofaalapkezelo.hu/backings/portfolioControl/getPortfolio'
        const body = { 'calculatorType': 'MPACalculator' }

        axios.post(url, body).then(function (response) {
            if (response.data) setFundList(response.data.result);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        subTitle: getValueFromParameterMap(page, 'sub-title'),
        content: getValueFromParameterMap(page, 'content'),
        fundList: getValueFromParameterMap(page, 'fund-list') && getValueFromParameterMap(page, 'fund-list').contentList,
    }

    const setFundDataById = (id, list) => {
        for (let i = 0; i < list.length; i++)
            if (getValueFromParameterMap(list[i], 'legacy-fund-id') == id)
                return list[i];
        return null;
    };

    const calculate = (params) => {
        if (params) {
            const url = 'https://www.diofaalapkezelo.hu/backings/rateControl/getRateDatas'
            const body = { 'input': params }

            axios.post(url, body).then(function (response) {
                if (response.data) {
                    if (response.data.result && response.data.result[0].errorText) setError(/*response.data.result[0].errorText*/'A megadott jelszó nem megfelelő!');
                    else setResult({ 'fund': getByParam(fundList, 'id', params.portfolioId), 'fundData': setFundDataById(params.portfolioId, _data.fundList), 'list': response.data.result });
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            setResult(null);
        }
    };

    const _export = (params) => {
        const url = 'https://www.diofaalapkezelo.hu/backings/rateControl/createRateExport';
        window.open(url + '/?input=' + encodeURI(JSON.stringify(params)), '_self');
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col items-center'>

            <PageTitle className='flex-1' titleClassName='xl:w-min' title={_data.title} subTitle={_data.subTitle} content={_data.content} breadcrumb />

            <div className={'w-full flex flex-col items-center px-site bg-primary-dark transition-all ' + (result ? 'pt-16 pb-24' : 'py-16')}>

                <div className='w-full max-w-boxed flex flex-col items-center px-content'>

                    <Input fundList={fundList} onCalculate={(params) => calculate(params)} onExport={(params) => _export(params)} error={error} />

                    {/* <button className='rounded-button' onClick={() => calculate({ 'portfolioId': '5', 'dateFrom': 'Dec 1, 2020 00:00:00 AM', 'dateTo': 'Dec 1, 2023 00:00:00 AM', 'password': '' })}>Teszt</button> */}

                </div>

            </div>

            {result && <div className='w-full flex flex-col items-center md:px-site md:pb-16 bg-neutral-50 transition-all'>

                <div className='w-full max-w-boxed page-title-content-offset'>

                    <Result fund={'hello'} result={result} />

                </div>

            </div>}

            <SubFooter>
                <SubFooterButtonItem title='Befektetési alapok' label='Ugrás Befektetési Alapjainkhoz' path='/befektetesi-alapok' />
                <SubFooterButtonItem title='Dokumentumok' label='Ugrás a Dokumentumokhoz' path='/kozzetetelek/befektetesi-alapok-dokumentumai' />
            </SubFooter>

        </NBossCMSPage>
    );
}

function Input(props) {
    const [fund, setFund] = useState('');
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    const [password, setPassword] = useState('');

    const [passwordNeeded, setPasswordNeeded] = useState(false);
    const [canBeCalculated, setCanBeCalculated] = useState(false);

    const startDate = new Date(2006, 0, 1);

    useEffect(() => {
        if (fund !== '') {
            const _fund = getByParam(props.fundList, 'id', fund);
            setPasswordNeeded(_fund.needPassword);
        }

        if (fund !== '' && start && end)
            setCanBeCalculated(true);
        else
            setCanBeCalculated(false);
    }, [fund, start, end, props.fundList]);

    useEffect(() => { setPassword(''); }, [passwordNeeded]);

    const getParams = () => { return { 'portfolioId': fund, 'dateFrom': convertToLegacyDate(start), 'dateTo': convertToLegacyDate(end), 'password': password } };

    const handleCalculate = () => {
        if (canBeCalculated && props.onCalculate) props.onCalculate(getParams());
    };

    const handleExport = () => {
        if (canBeCalculated && props.onExport) props.onExport(getParams());
    };

    return (
        <div className='w-full flex flex-col items-end gap-8'>

            <div className='w-full flex flex-col gap-2'>
                <div className='text-white'>Befektetési jegy</div>
                <select className='w-full generic-select' value={fund} onChange={(e) => setFund(e.target.value)}>
                    <option value='' disabled selected hidden>Befektetési alap kiválasztása</option>
                    {props.fundList && props.fundList.map((item, index) => <option key={item.id} value={item.id} className='generic-select-option'>{item.name}</option>)}
                </select>
            </div>

            <div className='w-full flex flex-col md:flex-row gap-16 md:gap-32'>

                <div className='flex-1 flex flex-col gap-2'>
                    <div className='text-white'>Kezdő dátum</div>
                    <DateSelect startDate={startDate} /*value={start}*/ onValueChange={(value) => setStart(value)} />
                </div>

                <div className='flex-1 flex flex-col gap-2'>
                    <div className='text-white'>Záró dátum</div>
                    <DateSelect startDate={startDate} /*value={end}*/ onValueChange={(value) => setEnd(value)} />
                </div>

            </div>

            {passwordNeeded && <div className='w-full flex flex-col gap-2'>
                <div className='text-white'>Befektetési jegyhez kapcsolódó jelszó</div>
                <input className='w-full line-input-white' placeholder='Jelszó' value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>}

            <div className='w-full md:w-auto flex flex-col items-center gap-4'>

                {!canBeCalculated && <div className='text-xs text-accent'>A számításhoz kérjük, töltse ki az adatokat!</div>}
                {props.error && <div className='text-xs text-accent'>{props.error}</div>}

                <div className='w-full flex flex-row gap-4'>
                    <button className='flex-1 rounded-button-accent-reverse' disabled={!canBeCalculated} onClick={() => handleExport()}>Export</button>
                    <button className='flex-1 rounded-button-accent-reverse' disabled={!canBeCalculated} onClick={() => handleCalculate()}>Számítás</button>
                </div>

            </div>

        </div>
    );
}

function Result(props) {
    const [fund, setFund] = useState(null);
    const [rateDataSum, setRateDataSum] = useState(null);
    const [rateDataList, setRateDataList] = useState(null);

    const [rateDataListOpen, setRateDataListOpen] = useState(null);

    const rateOverflowLimit = 10;

    useEffect(() => {
        if (props.result && props.result.list && props.result.list.length >= 2) {
            setFund(props.result.fund);
            setRateDataSum(props.result.list.pop().sumBlock);
            setRateDataList(props.result.list);
        }
    }, [props.result]);

    // useEffect(() => { console.log(rateDataSum); }, [rateDataSum]);

    const getDataConfig = () => {
        const config = [
            { 'label': 'Nyitó dátum', 'value': rateDataSum.openDate, 'config': { 'type': 'date' } },
            { 'label': 'Nyitó árfolyam', 'value': rateDataSum.openRate, 'config': { 'type': 'currency', 'currency': _data.currencyShortLabel } },
            { 'label': 'Záró dátum', 'value': rateDataSum.closeDate, 'config': { 'type': 'date' } },
            { 'label': 'Záró árfolyam', 'value': rateDataSum.closeRate, 'config': { 'type': 'currency', 'currency': _data.currencyShortLabel } },
            { 'label': 'Eltelt napok száma', 'value': rateDataSum.daysBetween },
            // { 'label': 'A pontos érték (%)', 'value': rateDataSum.valuePercent2 },
            { 'label': 'Az időszak során elért hozam', 'value': (rateDataSum.valuePercent1 * 100 / 100).toFixed(6) + '%' },
            // { 'label': 'Az időszak során elért hozam évesített értéke', 'value': (rateDataSum.valuePercent2 * 100 / 100).toFixed(6) + ' %' },
        ]

        if (rateDataSum.valuePercent2 != 0)
            config.push({ 'label': 'Az időszak során elért hozam évesített értéke', 'value': (rateDataSum.valuePercent2 * 100 / 100).toFixed(6) + ' %' });

        return config;
    };

    const _data = {
        currencyShortLabel: getValueFromParameterMap(getValueFromParameterMap(props.result.fundData, 'currency'), 'short-label'),
        currencySymbol: getValueFromParameterMap(getValueFromParameterMap(props.result.fundData, 'currency'), 'symbol'),
    };

    const getTableData = (data) => {
        const _d = [...data];
        return _d.reverse();
    };

    return (
        <div className='w-full grid grid-cols-1 md:grid-cols-2 floating-card-grid-gap'>

            {(fund && rateDataSum && rateDataList && rateDataList.length > 2) && <>

                <div className='floating-card flex flex-col'>

                    <div className='flex flex-col gap-2 floating-card-padding text-center md:text-left'>
                        <div className='text-2xl md:text-4xl font-display'>{fund.name}</div>
                        <div className='text-sm'>A számításhoz felhasznált adatok:</div>
                    </div>

                    <DataLister className='flex-1' data={getDataConfig()} />

                </div>

                <div className='floating-card flex flex-col gap-8 floating-card-padding'>

                    <div className='text-sm'>
                        Az Ön befektetésének évesítés nélküli hozama a megadott időpontok között:
                        <span className='font-bold'> {Math.round(rateDataSum.valuePercent1 * 100) / 100}%</span>.
                        {rateDataSum.valuePercent2 != 0 && <span> A befektetés éves szintre átszámított hozama
                            <span className='font-bold'> <Moment format='YYYY. MMMM DD.' withTitle>{new Date(rateDataSum.openDate)}</Moment> </span>
                            és
                            <span className='font-bold'> <Moment format='YYYY. MMMM DD.' withTitle>{new Date(rateDataSum.closeDate)}</Moment> </span>
                            között:
                            <span className='font-bold'> {Math.round(rateDataSum.valuePercent2 * 100) / 100}%</span>.</span>}
                    </div>

                    <div className='w-full flex-1 min-h-[320px]'>

                        <Graph data={rateDataList} currency={_data.currencyShortLabel} />

                    </div>

                </div>

                <div className={'relative col-span-full floating-card ' + ((rateDataList.length > rateOverflowLimit && !rateDataListOpen) ? 'h-96' : 'h-auto')}>
                    <Table data={{ 'config': { 'fields': [{ 'label': 'Eszközérték dátuma', 'key': 'date', 'type': 'date' }, { 'label': 'Nettó eszközérték', 'key': 'netto', 'type': 'currency', 'currency': _data.currencyShortLabel }, { 'label': 'Árfolyam', 'key': 'rate', 'type': 'currency', 'currency': _data.currencyShortLabel }] }, 'list': getTableData(rateDataList) }} />
                    {(rateDataList.length > rateOverflowLimit) && <div className={'w-full flex flex-col items-center justify-end p-8 ' + (rateDataListOpen ? '' : 'absolute bottom-0 h-[50%] bg-gradient-to-t from-white via-white')}>
                        <button className='rounded-button-primary' onClick={() => setRateDataListOpen(!rateDataListOpen)}>{rateDataListOpen ? 'Bezárás' : 'Mutass többet'}</button>
                    </div>}
                </div>

            </>}

        </div>
    );
};